@import '../../../styles/brutalist';

// Button size variants
.brutalist-button {
  &.brutalist-button-small {
    font-size: 0.9rem;
    padding: 5px 10px;
    border-width: 2px;
  }
  
  &.brutalist-button-medium {
    font-size: 1.2rem;
    padding: 10px;
    border-width: 4px;
  }
  
  &.brutalist-button-large {
    font-size: 1.4rem;
    padding: 15px;
    border-width: 4px;
  }
}

// Modal styling
.brutalist-modal {
  .modal-content {
    border-radius: 0;
    border: 6px solid $brutalist-text-dark;
    background-color: $brutalist-bg-light;
  }
  
  .modal-header {
    border-bottom: 4px solid $brutalist-text-dark;
    padding: 1rem;
    
    .close-button {
      margin-left: auto;
      font-size: 1.2rem;
      padding: 0 8px;
      border-width: 2px;
    }
  }
  
  .modal-body {
    padding: 1.5rem;
    max-height: 70vh;
    overflow-y: auto;
  }

  &.terms-modal {
    .modal-body {
      font-family: "Courier New", monospace;
      
      h1, h2, h3, h4, h5, h6 {
        font-weight: 800;
        text-transform: uppercase;
      }
      
      ul, ol {
        padding-left: 2rem;
      }
    }
  }
}

.brutalist-card-content {
  padding: 10px;
}

.brutalist-earth-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: $brutalist-background;
}

.brutalist-arrow-button {
  background-color: transparent;
  border: 2px solid $brutalist-text-dark;
  color: $brutalist-text-dark;
  font-weight: 800;
  padding: 5px 10px;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    transform: translateY(-2px);
    background-color: $brutalist-primary;
  }
  
  &--nav {
    background: none;
    border: none;
    color: $brutalist-text-dark;
    padding: 5px;
    transition: color 0.2s;
    
    &:hover {
      color: $brutalist-primary;
      transform: none;
      background-color: transparent;
    }
  }
  
  &--left {
    left: -30px;
  }
  
  &--right {
    right: -30px;
  }
  
  &--up,
  &--down {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .labeled-arrow-text {
    margin-bottom: 5px;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 1px;
  }
}

// Loading component
.brutalist-loading {
  padding: 3rem 1rem;
  text-align: center;
  position: relative;
  margin-top: 2rem;
  background-color: $brutalist-gray-100;
  
  h3 {
    font-weight: 800;
    font-size: 1.5rem;
    color: $brutalist-gray-900;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 1.5rem;
  }
  
  .loading-box {
    width: 40px;
    height: 40px;
    background-color: $brutalist-primary;
    margin: 0 auto 1.5rem;
    animation: loading-pulse 1.5s infinite alternate;
  }
  
  @keyframes loading-pulse {
    0% {
      transform: scale(1) rotate(0deg);
      background-color: $brutalist-primary;
    }
    100% {
      transform: scale(1.2) rotate(90deg);
      opacity: 0.7;
    }
  }
  
  // Size variants
  &.small {
    padding: 1.5rem 0.5rem;
    
    h3 {
      font-size: 1rem;
    }
    
    .loading-box {
      width: 25px;
      height: 25px;
    }
  }
  
  &.large {
    padding: 4rem 2rem;
    
    h3 {
      font-size: 2rem;
    }
    
    .loading-box {
      width: 60px;
      height: 60px;
    }
  }
}
