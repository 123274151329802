// preLogin.scss
@import "../../../styles/brutalist";

// Common styles for all pre-login pages
.prelogin-layout {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: $brutalist-background;
}

// Earth background container
.earth-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

// Legacy class for backward compatibility
.earth-card-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: $brutalist-background;
}

// Landing page specific styles
.landing-page-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10; // Make sure content is above the Earth
}

.emailButton {
  @include brutalist-button;
}

.landing-page-card {
  margin: 0 auto;
  margin-top: 50vh;
  transform: translateY(-50%);
  z-index: 1;

  @include brutalist-container;
}

.landing-page-guest-link {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  color: $brutalist-text-light;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: brutalist-rgba($brutalist-background, 0.6);
  padding: 10px 20px;
  z-index: 20; // Higher z-index to ensure visibility
  border-left: 4px solid $brutalist-primary;

  a {
    @include brutalist-link;
  }
}

.card-fixed-width {
  width: 350px;
  max-width: 90vw;
}

.brutalist-logo {
  @include brutalist-logo-container;
}

.brutalist-buttons {
  @include brutalist-grid-container;
}

.text-white {
  @include brutalist-title;

  color: $brutalist-text-light;
}

.text-blue {
  @include brutalist-title;

  color: $brutalist-primary;
}

// Login page specific styles
.login-page-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10; // Make sure content is above the Earth
}

.login-card-container {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-card,
.forgot-password-card {
  width: 350px;
  max-width: 90vw;
  transform: rotate(0deg) !important;

  &::before {
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: $brutalist-primary;
    z-index: 0;
  }
}

.brutalist-form {
  margin-top: 20px;
}

.brutalist-check {
  margin-top: 15px;

  .form-check-input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 0;
    border: 2px solid $brutalist-text-dark;

    &:checked {
      background-color: $brutalist-primary;
      border-color: $brutalist-primary;
    }
  }

  .form-check-label {
    font-weight: 600;
    font-size: 0.9rem;
  }

  a.brutalist-link {
    color: $brutalist-primary;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .brutalist-link-button {
    color: $brutalist-primary;
    font-weight: 700;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0;
    font-family: inherit;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }
}

// Terms of service modal styling
.terms-content {
  font-size: 0.9rem;
  line-height: 1.5;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 800;
    text-transform: uppercase;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
  }

  h1 {
    font-size: 1.5rem;
    border-bottom: 2px solid $brutalist-primary;
    padding-bottom: 0.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  ul,
  ol {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }

  a {
    @include brutalist-link;
  }

  .loading-message {
    text-align: center;
    padding: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px dashed $brutalist-gray-400;
    background-color: $brutalist-gray-100;
  }

  .error-message {
    color: $brutalist-warning;
    border: 2px solid $brutalist-warning;
    padding: 1rem;
    font-weight: 600;
    background-color: brutalist-rgba($brutalist-warning, 0.1);
  }

  .empty-message {
    text-align: center;
    padding: 1rem;
    font-style: italic;
    color: $brutalist-gray-600;
  }
}

.brutalist-label {
  font-weight: 700;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
  display: block;
}

.brutalist-alert {
  border-radius: 0;
  border: 3px solid $brutalist-warning;
  background-color: brutalist-rgba($brutalist-warning, 0.2);
  color: $brutalist-warning;
  font-weight: 600;
  padding: 10px;
  margin-top: 10px;
  position: relative;

  &::before {
    content: "!";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $brutalist-warning;
    color: $brutalist-text-light;
    font-weight: bold;
  }
}

.brutalist-success-alert {
  border-radius: 0;
  border: 3px solid #155724;
  background-color: #d4edda;
  color: #155724;
  font-weight: 600;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;

  h5 {
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
  }

  &::before {
    content: "✓";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #155724;
    color: $brutalist-text-light;
    font-weight: bold;
  }
}

.brutalist-signup-link {
  margin-top: 20px;
  color: $brutalist-text-light;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: brutalist-rgba($brutalist-background, 0.7);
  padding: 10px 20px;
  z-index: 1;
  border-left: 4px solid $brutalist-primary;
}

// Signup specific styles
.signup-card {
  width: 400px;
  max-width: 90vw;
  transform: rotate(0deg) !important;

  &::before {
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: $brutalist-primary;
    z-index: 0;
  }
}

// Brutalist input group styling
.brutalist-input-group-text {
  border-radius: 0;
  border: 3px solid $brutalist-text-dark;
  border-left: none;
  background-color: brutalist-rgba($brutalist-background, 0.05);
  padding: 0;
}

.brutalist-toggle-button {
  border: none;
  background: none;
  color: $brutalist-text-dark;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 10px;

  &:hover {
    color: $brutalist-primary;
    background: none;
  }

  &:active,
  &:focus {
    background: none !important;
    box-shadow: none !important;
  }
}
